import React from 'react';

import linkedin from "../assets/icons/LinkedinLogo.svg"
import mail from "../assets/icons/EnvelopeSimple.svg"
import phone from "../assets/icons/Phone.svg"

const contact = ()=>{
    return (
        <section className="">
            <article className="container flex items-center justify-between mx-auto font-medium max-w-6xl">
                <h1 className="pl-6 text-3xl font-light mt-10">Contact</h1>
            </article>
            <div className="w-full bg-blue-50 mt-4 absolute bottom-0 md:top-36 top-44">
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl">
                    <p className="mx-4 sm:m-0 sm:w-2/3">Neem gerust contact met me op voor een kennismakingsgesprek. Vertel me waar je behoeften liggen en ik vertel je wat ik voor jou kan betekenen.</p>
                    <div className="flex flex-col mt-12 mx-4 sm:mx-0">
                        <div className="flex flex-row">
                            <figure>
                                <img src={linkedin} alt="Linkedin"/>
                            </figure>
                            <p className="ml-6 hover:underline"><a className="hover:underline" target="_blank" href="https://nl.linkedin.com/in/joriskremer">Joris Kremer</a></p>
                        </div>
                        <div className="flex flex-row mt-2">
                            <figure>
                                <img src={mail} alt="Linkedin"/>
                            </figure>
                            <p className="ml-6"><a className="hover:underline" target="_blank" href="mailto:joris.kremer@gmail.com">joris.kremer@gmail.com</a></p>
                        </div>
                        <div className="flex flex-row mt-2">
                            <figure>
                                <img src={phone} alt="Linkedin"/>
                            </figure>
                            <p className="ml-6"><a className="hover:underline" target="_blank" href="tel:0618499785">+31 6 18 49 97 85</a></p>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default contact;