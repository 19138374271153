import React from 'react';

import Header from './Header';

import imageCard1 from '../assets/images/Card1.svg'
import imageCard2 from '../assets/images/Card2.svg'
import imageCard3 from '../assets/images/Card4.png'
import imageCard4 from '../assets/images/Card3.svg'

import presentatieImage1 from '../assets/images/presentatie1.jpeg'
import presentatieImage2 from '../assets/images/presentatie2.jpeg'

const home = () => {
    return (
        <section>
            <Header/>

            <section className="container px-4 md:mx-auto flex flex-col undefined pt-16 lg:max-w-6xl">
                <section className="lg:flex lg:flex-row lg:-mx-2 flex-wrap">
                    <div className="mb-6 w-full lg:w-1/3 lg:px-4">
                        <div className="bg-gray-100 shadow-md lg:h-auto">
                            <h1 className="text-gray-700 text-center text-xl pt-12 font-bold">Training</h1>
                            <p className="font-light text-center mx-3 mt-4">Klantgericht telefoneren, klantgericht
                                schrijven en commerciële vaardigheden voor customer service medewerkers</p>
                            <figure className="mt-6">
                                <img className="mx-auto h-60" src={imageCard1} alt="Card 1"/>
                            </figure>
                        </div>
                    </div>
                    <div className="mb-6 w-full lg:w-1/3 lg:px-4">
                        <div className="bg-gray-100 shadow-md font-bold lg:h-auto">
                            <h1 className=" text-gray-700 text-center text-xl pt-12">Coaching</h1>
                            <p className="font-light text-center mx-6 mt-4">1-op-1 Coaching: persoonlijke coaching in
                                één of meer gesprekken, afgestemd op jouw wensen en behoeften </p>
                            <figure className="mt-6">
                                <img className="mx-auto h-60" src={imageCard3} alt="Card 2"/>
                            </figure>
                        </div>
                    </div>
                    <div className="mb-6 w-full lg:w-1/3 lg:px-4">
                        <div className="bg-gray-100 shadow-md font-bold lg:h-auto">
                            <h1 className="text-gray-700 text-center text-xl pt-12">Interim</h1>
                            <p className="font-light text-center mx-3 mt-4">Heb je behoefte aan tijdelijke inzet van een
                                ervaren trainer, coach of teamleider binnen de klantcontactbranche? </p>
                            <figure className="mt-6">
                                <img className="mx-auto h-60" src={imageCard2} alt="Card 3"/>
                            </figure>
                        </div>
                    </div>
                </section>
            </section>
            <section className="w-full h-auto bg-blue-50 mt-16 divide-y-2 divide-indigo-900">
                <section className="containerpx-4 md:mx-auto flex flex-col undefined pt-4 lg:pt-12 lg:max-w-6xl">
                    <div className="lg:flex lg:flex-row lg:-mx-2 flex-wrap pb-12">
                        <div className="mx-4 mt-4 sm:m-0 sm:w-1/2 ">
                            <h1 className="text-indigo-900 font-bold text-xl pt-12 pl-6">Populaire trainingen</h1>
                            <ul className="list-disc pl-12 mt-2">
                                <li>Klantgericht telefoneren</li>
                                <li>Klantgericht schrijven</li>
                                <li>Commerciële vaardigheden voor customer service medewerkers</li>
                                <li>Gespreksvaardigheden en technieken</li>
                                <li>Effectief chatten via livechat en WhatsApp</li>
                                <li>Omgaan met weerstand, emoties en ‘lastig gedrag’</li>
                                <li>Coach de coach</li>
                                <li>Train de trainer</li>
                            </ul>
                        </div>
                        <figure className="mx-auto md:pl-12 pt-2 md:pt-0">
                            <img className="mx-auto h-96 object-cover" src={presentatieImage1}/>
                        </figure>
                    </div>
                </section>
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl pb-12">
                    <div className="lg:flex lg:flex-row lg:-mx-2 flex-wrap">
                        <div className="mx-4 mb-4 sm:w-1/2 sm:m-0">
                            <h1 className="text-indigo-900 font-bold text-xl pt-2 pl-6">Andere trainingen</h1>
                            <ul className="list-disc pl-12 mt-2">
                                <li className="mb-2">Klantcontact: Adviesvaardigheden, Hospitality, Klanttypen en gedrag
                                    (DISC), Webcare.
                                </li>
                                <li className="mb-2">Persoonlijke effectiviteit: Effectief communiceren, Kernkwadrant,
                                    Persoonlijke kracht en uitstraling.
                                </li>
                                <li className="mb-2">Leidinggevenden: Mensgericht motiveren, Leiderschap,
                                    Resultaatgericht coachen, Feedback geven en ontvangen, Beweging binnen teams,
                                    Presenteren, Situationeel leidinggeven.
                                </li>
                                <li className="mb-2">Teamcommunicatie: Teambuilding/teamcoaching, Intervisie, Feedback
                                    geven en ontvangen.
                                </li>
                                <li className="mb-2">Kwaliteit: Train-de-trainer, Ontwikkeling en implementatie van QM,
                                    Uitvoeren van een Quickscan / 0- meting, Adviesrapporten.
                                </li>
                            </ul>
                        </div>
                        <figure className=" mx-auto md:pl-12 pt-2 md:pt-0">
                            <img className="mx-auto h-96 object-cover" src={presentatieImage2}/>
                        </figure>
                    </div>
                </section>
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl pb-12">
                    <div className="lg:flex lg:flex-row lg:-mx-2 flex-wrap">
                        <div className="mx-4 mb-4 sm:w-1/2 sm:m-0">
                            <h1 className="text-indigo-900 font-bold text-xl pt-2 pl-6">Coaching</h1>
                            <ul className="list-disc pl-12 mt-2">
                                <li className="mb-2">1-op-1 Coaching: persoonlijke coaching in één of meer gesprekken
                                </li>
                                <li className="mb-2">Afgestemd op jouw wensen en behoeften</li>
                                <li className="mb-2">Inzicht in je eigen competenties</li>
                                <li className="mb-2">Lastige situaties oefenen in een veilige omgeving</li>
                            </ul>
                        </div>
                        <figure className=" mx-auto md:pl-12 pt-2 md:pt-0">
                            <img className="mx-auto h-64 object-cover" src={imageCard3}/>
                        </figure>
                    </div>
                </section>
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl pb-12">
                    <div className="lg:flex lg:flex-row lg:-mx-2 flex-wrap">
                        <div className="mx-4 mb-4 sm:w-1/2 sm:m-0">
                            <h1 className="text-indigo-900 font-bold text-xl pt-2 pl-6">Interim</h1>
                            <p className="font-light mt-4 pl-6">Heb je behoefte aan een tijdelijke inzet van een ervaren
                                trainer, coach of teamleider binnen de klantcontactbranche? Neem dan contact met me
                                op!</p>
                        </div>
                        <figure className="mx-auto md:pl-12 pt-2 md:pt-0">
                            <img className="mx-auto h-96 object-cover" src={imageCard4}/>
                        </figure>
                    </div>
                </section>
            </section>
            <footer className="text-center bg-blue-50">
                <p className="inline-block text-gray-500 hover:underline"><a target="_blank" href="https://irenedekoning.nl">Made with ♥ by iTensio</a></p>
            </footer>
        </section>
    );
}

export default home;