import React from 'react'

import headerImage from '../assets/images/headerImage.svg'
import checkMark from '../assets/images/checkMark.svg'

const header = () => {
    return (
        <header className="w-full h-auto bg-blue-50 mt-2">
            <section className="container flex items-center justify-between mx-auto font-medium max-w-6xl">
                <div className="container md:flex justify-between mx-auto font-medium max-w-6xl h-auto">
                    <div>
                        <h1 className="text-indigo-900 font-bold text-xl pt-12 pl-6">Coaching en trainingen</h1>
                        <div className="flex flex-row ml-12 mt-4">
                            <figure>
                                <img src={checkMark} alt="checkMark"/>
                            </figure>
                            <p className="text-gray-700 font-light my-auto ml-4">Specialist in het optimaliseren van klantcontact</p>
                        </div>
                        <div className="flex flex-row ml-12 mt-2">
                            <figure>
                                <img src={checkMark} alt="checkMark"/>
                            </figure>
                            <p className="text-gray-700 font-light my-auto ml-4">Haal het maximale uit jezelf en je medewerkers</p>
                        </div>
                        <div className="flex flex-row ml-12 mt-2">
                            <figure>
                                <img src={checkMark} alt="checkMark"/>
                            </figure>
                            <p className="text-gray-700 font-light my-auto ml-4">Praktijkgerichte trainingen op niveau met blijvend resultaat</p>
                        </div>

                    </div>
                    <figure className="">
                        <img className="mx-auto" src={headerImage} alt="headerImage"/>
                    </figure>
                </div>
            </section>
        </header>
    );
}

export default header;