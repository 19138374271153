import React from 'react';

import profilePicture from '../assets/images/jorisKremerProfielfoto.jpeg'
import imageCard1 from '../assets/images/Card1.svg'
import presentatieImage1 from '../assets/images/presentatie1.jpeg'
import presentatieImage2 from '../assets/images/presentatie2.jpeg'

const aboutme = () => {
    return (
        <section>
            <article className="container flex items-center justify-between mx-auto font-medium max-w-6xl">
                <h1 className="pl-6 text-3xl font-light mt-10">Wie ben ik?</h1>
            </article>
            <div className="w-full bg-blue-50 mt-4 pb-12 md:absolute md:bottom-0 md:top-36 ">
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl">
                    <div className="flex flex-col sm:flex-row">
                        <div className="w-full px-4 sm:m-0 sm:w-2/3 my-auto">
                            <p>Ik ben Joris Kremer trainer, coach en adviseur binnen de klantcontactbranche waar thema’s
                                als klantbeleving, (online)dienstverlening, leiderschap, communicatieve- en
                                commerciële vaardigheden centraal staan.</p>
                            <br/>
                            <p>Sinds 2005 verzorg ik ontwikkeltrajecten voor organisaties in de financiële sector,
                                telecomproviders, verzekeringsmaatschappijen, energieleveranciers, medische sector en
                                (semi-) overheid.</p>
                        </div>
                        <div className="w-full px-4 mt-6 sm:m-0 sm:w-1/3">
                            <figure>
                                <img className="rounded-full mx-auto" src={profilePicture} alt=""/>
                            </figure>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default aboutme;