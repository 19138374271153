import './index.css'
import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

import Aboutme from './components/Aboutme';
import Customers from './components/Customers';
import Contact from './components/Contact';
import Home from './components/Home';

function App() {
    return (
        <Router>
            <div>
                <nav className="w-full">
                    <div className="container flex items-center justify-between mx-auto font-medium max-w-6xl">
                        <ul className="list-reset flex py-4">
                            <li className="px-2 pl-6 text-gray-700 flex flex-row items-center"><h1 className="text-lg">
                                <Link to="/">Joris Kremer</Link></h1>
                            </li>
                        </ul>
                        <ul className="list-reset flex py-2 pr-2 lg:pr-8">
                            <li className="mr-6 text-gray-700 text-sm md:text-lg hover:underline cursor-pointer">
                                <Link to="/aboutme">Over mij</Link>
                            </li>
                            <li className="mr-6 text-gray-700 text-sm md:text-lg hover:underline cursor-pointer">
                                <Link to="/customers">Klanten</Link>
                            </li>
                            <li className="mr-6 text-gray-700 text-sm md:text-lg hover:underline cursor-pointer">
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <Switch>
                    <Route path="/aboutme">
                        <Aboutme/>
                    </Route>
                    <Route path="/customers">
                        <Customers/>
                    </Route>
                    <Route path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
