import React from 'react';

import logo1 from '../assets/logos/kpnLogo.png'
import logo2 from '../assets/logos/abnAmbroLogo.png'
import logo3 from '../assets/logos/SBBLogo.png'
import logo4 from '../assets/logos/mollieLogo.png'
import logo5 from '../assets/logos/freoLogo.png'
import logo6 from '../assets/logos/gazelleLogo.png'
import logo7 from '../assets/logos/mediaHuisLogo.svg'
import logo8 from '../assets/logos/minBuitenlandsLogo.jpeg'
import logo9 from '../assets/logos/bouwInvestLogo.png'
import logo10 from '../assets/logos/EarlyBridgeLogo.png'
import logo11 from '../assets/logos/TeleTraianLogo.png'
import logo12 from '../assets/logos/EngieLogo.png'

const customers = ()=>{
    return (
        <section>
            <article className="container flex items-center justify-between mx-auto font-medium max-w-6xl">
                <h1 className="pl-6 text-3xl font-light mt-10">Klanten</h1>
            </article>
            <div className="w-full bg-blue-50 mt-4">
                <section className="container px-4 md:mx-auto flex flex-col undefined pt-12 lg:max-w-6xl">
                    <p className="w-2/3">Organisaties waarmee ik samenwerk: </p>
                    <br/>
                    <div className="flex flex-wrap sm:pb-4">
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain" src={logo1} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4  h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo2} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo3} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-6" src={logo4} alt=""/>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap sm:pb-4">
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo5} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo6} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-8" src={logo7} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo8} alt=""/>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap pb-8">
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain" src={logo9} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo10} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-4" src={logo11} alt=""/>
                                </figure>
                            </div>
                        </div>
                        <div className="w-full mt-4 sm:w-1/2 lg:w-1/4 h-60">
                            <div className="mx-4 bg-blue-100 h-full">
                                <figure className="h-full">
                                    <img className="h-full object-contain p-6" src={logo12} alt=""/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default customers;